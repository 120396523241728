import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { padStart } from 'lodash-es';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';

const defaultElement = 'div';

type CountdownProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  {
    expiredTime: string | Date;
    onExpiredTime?: () => void;
  }
>;

const calculateTimeLeft = (expiredTime: string | Date) => {
  const utcExpiredTime = dayjs.utc(expiredTime);
  const utcNow = dayjs.utc();

  return {
    hour: padStart(String(Math.floor(utcExpiredTime.diff(utcNow, 'hour') % 24)), 2, '0'),
    minute: padStart(String(Math.floor(utcExpiredTime.diff(utcNow, 'minute') % 60)), 2, '0'),
    second: padStart(String(Math.floor(utcExpiredTime.diff(utcNow, 'second') % 60)), 2, '0'),
  };
};

const Countdown: <E extends React.ElementType = typeof defaultElement>(
  props: CountdownProps<E>
) => JSX.Element = ({ expiredTime, onExpiredTime, ...props }) => {
  const [time, setTime] = useState(calculateTimeLeft(expiredTime));

  useEffect(() => {
    let timer = setTimeout(() => {
      setTime(calculateTimeLeft(expiredTime));
    }, 1000);
    
    if (time.hour === '00' && time.minute === '00' && time.second === '00') {
      onExpiredTime && onExpiredTime()
    }

    return () => clearTimeout(timer);
  });

  return (
    <Box as={defaultElement} {...props}>
      {Object.values(time).join(':')}
    </Box>
  );
};

export default Countdown;
