import { Price, Option, EnumOptionKind, EnumPriceUnit, EnumAdvancedGroupPriceType } from 'src/types';
import { compact, max } from 'lodash-es'
import pluralize from 'pluralize';

type FakeOption = {
  yachtTrip?: boolean;
  kind: Option['kind'];
  priceUnit: Option['priceUnit'];
  groupPriceType?: Option['groupPriceType'];
}
export const canShowAverageAdvancedGroupPrice = (option: Option | FakeOption) => {
  return (
    !option.yachtTrip &&
    option.kind === EnumOptionKind.shortAdventure &&
    option.priceUnit === EnumPriceUnit.perGroup &&
    option.groupPriceType === EnumAdvancedGroupPriceType.pricePerGroupWithAdditionalPaxAddon
  );
};

export const getMaxQuantityFromPrices = (prices: Price[]) => {
  const quantities = compact(prices.map((o) => (o.maxQtyForPrice || o.minQtyForPrice)))
  return max(quantities) || 0
}

export const displaySupplierResponseTime = (responseTimeInSeconds: number) => {
  const minutes = Math.floor(responseTimeInSeconds / 60)
  const hours = Math.floor(responseTimeInSeconds / 3600)
  const days = Math.floor(responseTimeInSeconds / 3600 / 24)
  if (minutes < 60) {
    return pluralize('minute', minutes, true)
  } else if (hours < 24) {
    return pluralize('hour', hours, true)
  } else if (days >= 1) {
    return pluralize('day', days, true)
  }
}
