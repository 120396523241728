import React, { createContext, useEffect, useState } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';

export const LoginModalContext = createContext<{
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}>({
  show: false,
  setShow: () => {}
})

const LoginModalProvider: React.ReactFCWithChildren = ({ children }) => {
  const [show, setShow] = useState<boolean>(false)

  return (
    <LoginModalContext.Provider value={{ show, setShow }}>
      {children}
    </LoginModalContext.Provider>
  )
}

export default LoginModalProvider
