import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import useInitialData from 'src/hooks/useInitialData';
import MobileSidenavAndButton from 'src/components/MobileSidenavAndButton';
import { CartButton, CartPopup } from 'src/components/CartPopup';
import DestinationDropdown from './DestinationDropdown';
import AboutDropdown from './AboutDropdown';
import ProfileDropdown from './ProfileDropdown';
import { AppRoutes } from 'src/lib/routes';
import { groupBy, sortBy } from 'lodash-es';
import HeaderSearch from 'src/components/HeaderSearch';
import { ControlHeaderPageNavContext } from 'src/contexts/ControlHeaderPageNavProvider';
import classNames from 'classnames';
import useCurrentUser from 'src/hooks/useCurrentUser';
import CurrencySelector from 'src/components/CurrencySelector';
import { useRouter } from 'next/router';
import ClientOnlyComponent from './ClientOnlyComponent';
import Filter from 'src/components/filter';
import useFilter from 'src/hooks/useFilter';
import LogoImg from 'public/images/logo.png';
import ImageWithFallback from './ImageWithFallback';
import styles from 'src/styles/modules/components/header.module.scss';
import GiftDropdown from './GiftDropdown';

const HIDE_HEADER_FILTER_ROUTES = [
  '/cities/[id]',
  '/countries/[id]',
  '/collections/[id]',
  '/collections/[id]/[cateId]'
];

const Header: React.FC = () => {
  const { showHeader, setShowHeader, hideHeaderFilter, setHideHeaderFilter } = useContext(ControlHeaderPageNavContext);
  const [currentUser] = useCurrentUser();
  const { countries, cities } = useInitialData();
  const router = useRouter();
  const groupedCities = {
    ...groupBy(sortBy(cities.filter(o => o.optionsCount > 0), ['name']), (city) => city.countryId),
    0: sortBy(cities.filter((city) => city.featured), [(o) => -o.optionsCount]),
  };
  const [hideAllPopups, setHideAllPopups] = useState(false)
  const [showCartPopup, setShowCartPopup] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      const toggle = () => {
        setShowHeader(true);
        setShowCartPopup(false);
      };
      router.events.on('routeChangeComplete', toggle);
      return () => {
        router.events.off('routeChangeComplete', toggle);
      }
    }
  }, [router, setShowHeader]);

  useEffect(() => {
    if (router.isReady) {
      const hideFilterHeader = () => {
        if (HIDE_HEADER_FILTER_ROUTES.includes(router.pathname)) {
          setHideHeaderFilter(true);
        } else {
          setHideHeaderFilter(false);
        }
      };
      hideFilterHeader();
      router.events.on('routeChangeComplete', hideFilterHeader);
      return () => {
        router.events.off('routeChangeComplete', hideFilterHeader);
      };
    }
  }, [router, setHideHeaderFilter]);

  return (
    <>
      <div
        id="js-header"
        className={classNames('tw-top-0 tw-w-full tw-left-0 tw-z-100 tw-bg-transparent tw-border-0 tw-font-styleSans tw-font-semibold', {
          'tw-sticky': showHeader,
          'tw-invisible': !showHeader
        })}
      >
        <div className={`tw-bg-brand-green ${styles.header}`}>
          <div className="container">
            <nav className="navbar navbar-primary tw-justify-between tw-py-3 md:tw-py-4 tw-px-0 tw-flex-nowrap">
              <div className="tw-flex tw-flex-grow-0 tw-flex-shrink-0 tw-items-center" onMouseEnter={() => setHideAllPopups(true)}>
                <div className="tw-hidden lg:tw-block">
                  <Link legacyBehavior                     href={AppRoutes.rootRoute({}, { sr: 1 }).toPath()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <a className="navbar-brand tw-p-0">
                      <ImageWithFallback src={LogoImg} className="tw-static tw-inline-block tw-h-10 tw-w-auto" alt="Seek Sophie" />
                    </a>
                  </Link>
                </div>

                <div className="tw-mr-4 tw-hidden lg:tw-block">
                  <DestinationDropdown countries={countries} cities={groupedCities} />
                </div>

                <div className="tw-mr-4 tw-hidden lg:tw-block">
                  <GiftDropdown />
                </div>

                <div className="tw-mr-4 tw-hidden lg:tw-block">
                  <AboutDropdown />
                </div>

                <div className="tw-hidden lg:tw-block">
                  <Link legacyBehavior href={AppRoutes.magazinesRoute().toPath()}>
                    <a>
                      <span className="tw-text-brand-navy">Field Notes</span>
                    </a>
                  </Link>
                </div>
              </div>

              <div className="tw-relative tw-flex tw-items-center tw-flex-grow tw-justify-between lg:tw-justify-start tw-mx-0 md:tw-mx-5" onMouseEnter={() => setHideAllPopups(false)}>
                <div className="tw-flex tw-items-center">
                  <ClientOnlyComponent>
                    <div className="tw-mr-3 tw-block lg:tw-hidden">
                      <MobileSidenavAndButton
                        currentUser={currentUser}
                        cities={groupedCities}
                        countries={countries}
                      />
                    </div>
                  </ClientOnlyComponent>

                  <div className="tw-block lg:tw-hidden tw-mr-4">
                    <CartButton
                      isShowCart={showCartPopup}
                      onClick={() => setShowCartPopup(!showCartPopup)}
                    />
                  </div>
                </div>

                <div className="tw-block tw-absolute tw-transform tw--translate-x-1/2 tw-left-1/2 tw--top-1 lg:tw-hidden">
                  <Link legacyBehavior                     href={AppRoutes.rootRoute({}, { sr: 1 }).toPath()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <a className="tw-p-0">
                      <ImageWithFallback src={LogoImg} className="tw-static tw-inline-block tw-h-10 tw-w-auto" alt="Seek Sophie" />
                    </a>
                  </Link>
                </div>

                <HeaderSearch
                  hideAllPopups={hideAllPopups}
                />
              </div>

              <div className="tw-flex tw-flex-grow-0 tw-flex-shrink-0 tw-items-center" onMouseEnter={() => setHideAllPopups(true)}>
                <div className="tw-hidden lg:tw-block tw-mr-4">
                  <CartButton
                    isShowCart={showCartPopup}
                    onClick={() => setShowCartPopup(!showCartPopup)}
                  />
                </div>

                <div className="tw-hidden lg:tw-block tw-mr-4">
                  <CurrencySelector />
                </div>

                {currentUser ? (
                  <div className="tw-hidden lg:tw-block">
                    <ProfileDropdown />
                  </div>
                ) : (
                  <div className="tw-hidden lg:tw-block">
                    <Link legacyBehavior href={AppRoutes.loginRoute().toPath()}>
                      <a className="tw-text-brand-navy tw-font-styleSans tw-font-semibold">Login</a>
                    </Link>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>

        <div id="extra-header"></div>
      </div>
      <CartPopup
        isShowCart={showCartPopup}
        isShowHeader={showHeader}
        isEventHeader={false}
        onClick={() => setShowCartPopup(false)}
      />
    </>
  );
};

export default Header;
