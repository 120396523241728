import classNames from 'classnames';
import UserAvatar from 'src/components/account/UserAvatar';
import { RiCloseCircleFill } from 'react-icons/ri'
import { User } from 'src/types';
import { saveLastLoginMethod } from 'src/lib/last_login_method'
import { AppRoutes } from 'src/lib/routes';
import styles from 'src/styles/modules/components/login.module.scss';
import { useRouter } from 'next/router';

const protectEmail = (email: string) => {
  var [name, _] = email.split("@");
  return email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, `$1${"•".repeat(name.length - 2)}@$2`)
}

const protectPhoneNumber = (phone: string) => {
  return (`${"•".repeat(phone.length - 3)}${phone.slice(phone.length - 3)}`)
}

const WelcomeBack: React.FC<{ isLoginPage?: boolean, user: User; onClear?: () => void }> = ({ isLoginPage, user, onClear }) => {
  const router = useRouter()

  return (
    <>
      {user && (
        <div>
          <h2 className={styles.title}>{isLoginPage ? `Welcome back, ${user.name}` : 'Account Exists'}</h2>
          <div className={classNames("tw-text-center", { "tw-hidden": isLoginPage })}>Looks like you already have an account. Please log in instead.</div>
          <div className="tw-flex tw-justify-center">
            <UserAvatar imageDataUrl={String(user.avatarUrl)} className="tw-h-20 tw-w-20 tw-mx-auto tw-mb-4 tw-mt-6" />
          </div>
          <div className="tw-text-center tw-mb-6 tw-relative tw-h-30">
            {
              user.lastLoginMethod === 'whatsapp'
                ? protectPhoneNumber(user.phoneNumber)
                : user.lastLoginMethod === 'facebook'
                  ? user.name
                  : isLoginPage
                    ? protectEmail(user.email)
                    : user.email

            }
            <RiCloseCircleFill
              className="tw-absolute tw-bottom-3 tw-p tw-text-brand-navy tw-cursor-pointer tw-ml-1"
              width={12}
              height={12}
              onClick={() => {
                if (typeof onClear === 'function') {
                  onClear()
                } else {
                  saveLastLoginMethod(undefined)
                  window.location.href = user.lastLoginMethod === 'whatsapp'
                    ? AppRoutes.loginWhatsappRoute({}, router.query).toUrl()
                    : AppRoutes.loginRoute({}, router.query).toUrl()
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeBack;
