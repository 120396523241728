import {
  Option,
  OrderItem,
  Invoice,
  DetailedTimeslot,
  EnumOptionKind,
  EnumOptionCancelType,
  EnumOrderStatus,
  OrderItemCustomerTrip,
  EnumTransportItemKind,
  User
} from 'src/types';
import dayjs from 'dayjs';
import { UITimeFormat, UIDateFormat, DBDateFormat } from 'src/lib/constants';
import pluralize from 'pluralize';

export function getDurationDay(option: Option) {
  const day =
    option.durationNights && option.durationNights > 0
      ? option.durationNights
      : option.durationDays
      ? option.durationDays - 1
      : 0;
  return day < 0 ? 0 : day;
}

export const calendarPriceUnit = (orderItem: { totalQuantity: number }) => {
  if (orderItem.totalQuantity === 0) return '';
  return `for ${pluralize('x person', orderItem.totalQuantity, true)}`;
};

export function getCancelPolicyDescription(
  orderItem: OrderItem,
  option?: Option
) {
  if (orderItem.isGift) {
    return 'Gifts are non-refundable'
  } else if (option) {
    const noCancellation = option.cancelType === EnumOptionCancelType.noCancellation || !option.cancelType || !orderItem.canCancel

    if (noCancellation) {
      if (orderItem.status === EnumOrderStatus.Processing) {
        return 'Free cancellation before your request has been accepted by your host. After your booking has been confirmed, it cannot be cancelled.'
      } else {
        return 'This booking is non-refundable or cancellable'
      }
    } else {
      return `Free cancellation before ${orderItem.startTime ? `${dayjs(orderItem.startTime).format(UITimeFormat)} on ` : ''} ${dayjs(orderItem.cancelableDate).format(UIDateFormat)
        }`
    }
  }
}

export function hasRequiredCustomerInfo(option: Option) {
  return option.requiredCustomerInfos && option.requiredCustomerInfos.length > 0;
}

export function isRequiredCustomerInfoDone(orderItem: OrderItemCustomerTrip) {
  return orderItem.customers.length > 0 && orderItem.customers.every((o) => o.isSubmitted);
}

export function hasRequiredTransportItem(orderItem: OrderItemCustomerTrip) {
  const option = orderItem.option;
  return (
    (option.transportItems || []).filter((i) => i.kind === EnumTransportItemKind.pickup).length > 0
  );
}

export function isRequiredTransportItemDone(orderItem: OrderItemCustomerTrip) {
  const option = orderItem.option
  const hasSubmittedCustomerInfo = orderItem.customers.filter((o) => o.isSubmitted).length > 0;
  return (
    orderItem.selectedTransportItem &&
    (!hasRequiredCustomerInfo(option) || hasSubmittedCustomerInfo)
  );
}

export function getAllowedCustomerActions(orderItem: OrderItemCustomerTrip | undefined, user: User | undefined): {
  cancelBooking: boolean;
  changeDate: boolean;
  addGuest: boolean;
  createReview: boolean;
  shareTrip: boolean;
} {
  const order = orderItem?.order

  if (!orderItem || !order || orderItem.isGift || user?.id !== order.userId)
    return { cancelBooking: false, changeDate: false, addGuest: false, createReview: false, shareTrip: false };

  const checkoutPaymentSuccessed =
    orderItem.checkoutPayment &&
    ['captured', 'refunded', 'partial_refunded'].includes(orderItem.checkoutPayment.status);
  const twoMonthFromStartDate = dayjs(orderItem.startDate).add(2, 'month').format(DBDateFormat);

  if (EnumOrderStatus.Processing === orderItem.status) {
    return { cancelBooking: true, changeDate: false, addGuest: false, createReview: false, shareTrip: order.canShareTrip };
  } else if (EnumOrderStatus.Confirmed === orderItem.status) {
    let actions = { cancelBooking: false, addGuest: false, changeDate: false, createReview: false, shareTrip: order.canShareTrip }
    if (checkoutPaymentSuccessed) {
      actions = {...actions, cancelBooking: true, addGuest: Number(orderItem.numGuestCanAdd) > 0, changeDate: orderItem.canChangeDate}
    }
    return actions
  } else if (
    EnumOrderStatus.Completed === orderItem.status &&
    twoMonthFromStartDate > dayjs().format(DBDateFormat) &&
    orderItem.review &&
    !orderItem.review.rating
  ) {
    return { cancelBooking: false, changeDate: false, addGuest: false, createReview: true, shareTrip: false };
  } else if (EnumOrderStatus.Awaiting === orderItem.status) {
    return { cancelBooking: false, changeDate: true, addGuest: false, createReview: false, shareTrip: order.canShareTrip};
  } else if (EnumOrderStatus.HavingChangeRequest === orderItem.status) {
    return { cancelBooking: false, changeDate: false, addGuest: false, createReview: false, shareTrip: order.canShareTrip };
  } else {
    return { cancelBooking: false, changeDate: false, addGuest: false, createReview: false, shareTrip: false };
  }
}

export function submitRequiredInformationDate(orderItem: OrderItem) {
  const startDatePrevDay = dayjs(orderItem.startDate).add(-1, 'day');
  return dayjs(orderItem.startDate).isAfter(dayjs())
    ? startDatePrevDay.format(UIDateFormat)
    : dayjs(orderItem.startDate).format(UIDateFormat);
}

export function getPaymentDescription({
  order,
  orderItem,
  invoice,
}: {
  order?: {
    number?: string;
    customerName?: string;
    customerEmail?: string;
  };
  orderItem?: OrderItem;
  invoice?: Invoice;
}) {
  if (invoice && order?.number) {
    return `Topup payment for order #${order.number}`;
  } else if (orderItem && order) {
    const name = orderItem?.giftCardId
      ? `GC#${orderItem?.giftCardId}`
      : `SAO#${orderItem.supplierActivityOptionId}`;
    return order ? `${order.customerName} - ${order.customerEmail} - ${name}` : name;
  } else {
    return '';
  }
}

export const getTotalQty = (obj: {
  quantity?: number;
  childQuantity?: number;
  localQuantity?: number;
  childLocalQuantity?: number;
}) => {
  return (obj.quantity || 0) +
    (obj.childQuantity || 0) +
    (obj.localQuantity || 0) +
    (obj.childLocalQuantity || 0);
};

export function displayTimeslot(data: { startTime: string; endTime: string }) {
  return `${dayjs.utc(data.startTime).format(UITimeFormat)} - ${dayjs
    .utc(data.endTime)
    .format(UITimeFormat)}`;
}

export function displayStartEndDate(data: { startDate: string; endDate?: string | null }) {
  if (data.endDate && dayjs(data.endDate).isAfter(data.startDate)) {
    return `${dayjs(data.startDate).format(UIDateFormat)} - ${dayjs(data.endDate).format(
      UIDateFormat
    )}`;
  } else {
    return dayjs(data.startDate).format(UIDateFormat);
  }
}

export function humanizeInputQtyValue(
  option: Option,
  qtyData: {
    quantity: number;
    childQuantity: number;
    localQuantity: number;
    childLocalQuantity: number;
  }
) {
  if (getTotalQty(qtyData) === 0) {
    return 'Guests';
  } else {
    let text = [] as string[];
    if (option.useChildPrices) {
      let prefix = option.hasLocalPrice ? 'Foreigner ' : '';
      if (qtyData.quantity > 0) text.push(qtyData.quantity + ' x ' + prefix + 'Adult');
      if (qtyData.childQuantity > 0) text.push(qtyData.childQuantity + ' x ' + prefix + 'Child');
      if (qtyData.localQuantity > 0) text.push(qtyData.localQuantity + ' x Local Adult');
      if (qtyData.childLocalQuantity > 0) text.push(qtyData.childLocalQuantity + ' x Local Child');
    } else {
      if (option.hasLocalPrice) {
        if (qtyData.quantity > 0)
          text.push(qtyData.quantity + ' x Foreigner' + (qtyData.quantity > 1 ? 's' : ''));
        if (qtyData.localQuantity > 0)
          text.push(qtyData.localQuantity + ' x Local' + (qtyData.localQuantity > 1 ? 's' : ''));
      } else {
        if (qtyData.quantity > 0)
          text.push(qtyData.quantity + ' x Person' + (qtyData.quantity > 1 ? 's' : ''));
      }
    }
    return text.join(', ');
  }
}

export function bgClassByOrderStatus(status: string) {
  return {
    'tw-text-grey': status === 'rescheduling' || status === 'processing' || status === 'awaiting' || status === 'having_change_request',
    'tw-text-black': status === 'declined' || status === 'cancelled',
    'tw-text-green-light': status === 'completed',
    'tw-text-orange': status === 'confirmed',
  };
}

export function getDateAccomInfo(
  option: Option,
  bookingFormValues: {
    startDate: string | null;
    endDate: string | null;
    detailedTimeslotId: number | null;
    quantity: number;
    localQuantity: number;
    childLocalQuantity: number;
    childQuantity: number;
  },
  detailedTimeslots: DetailedTimeslot[],
  hidePrice?: boolean
) {
  const currentDetailedTimeslot = detailedTimeslots.find(
    (o) => o.id === bookingFormValues.detailedTimeslotId
  );

  return [
    !hidePrice && getTotalQty(bookingFormValues) > 0
      ? `Price ${calendarPriceUnit({
          totalQuantity: getTotalQty(bookingFormValues),
        })}`
      : null,
    bookingFormValues.startDate && dayjs(bookingFormValues.startDate).format('DD MMM'),
    bookingFormValues.endDate && dayjs(bookingFormValues.endDate).format('DD MMM'),
    !bookingFormValues.endDate &&
      bookingFormValues.startDate &&
      option.kind === EnumOptionKind.adventure &&
      getDurationDay(option) > 0 &&
      dayjs(bookingFormValues.startDate).add(getDurationDay(option), 'day').format('DD MMM'),
    bookingFormValues.startDate && bookingFormValues.endDate && option.kind === EnumOptionKind.stay
      ? pluralize(
          'night',
          dayjs(bookingFormValues.endDate).diff(bookingFormValues.startDate, 'day'),
          true
        )
      : null,
    currentDetailedTimeslot && dayjs.utc(currentDetailedTimeslot.startTime).format('HH:mm')
  ]
    .filter((v) => v)
    .join(' - ');
}
