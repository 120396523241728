import { User } from 'src/types';
import { Camelized } from 'humps';
import { pick } from 'lodash-es';

const LAST_LOGIN_METHOD = 'ss_last_login_method'

export function saveLastLoginMethod(data: User | undefined) {
  if (typeof window === 'object') {
    if (data === undefined) {
      window.localStorage.removeItem(LAST_LOGIN_METHOD)
    } else {
      window.localStorage.setItem(LAST_LOGIN_METHOD, JSON.stringify(
        pick(data, ['lastLoginMethod', 'name', 'email', 'phoneNumber', 'avatarUrl'])
      ))
    }
  }
}

export function getLastLoginMethod(): User | Camelized<User> | undefined {
  if (typeof window === 'object') {
    const data = window.localStorage.getItem(LAST_LOGIN_METHOD)
    return data ? JSON.parse(data) : undefined
  } else {
    return undefined
  }
}
