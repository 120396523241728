import 'src/styles/application.scss';
import Head from 'next/head';
import imgLogo from 'public/images/logo.jpg';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import InitialDataProvider from 'src/contexts/InitialDataProvider';
import ConfirmDialogProvider from 'src/contexts/ConfirmDialogProvider';
import ControlHeaderPageNavProvider from 'src/contexts/ControlHeaderPageNavProvider';
import CartProvider from 'src/contexts/CartProvider';
import CurrentUserProvider from 'src/contexts/CurrentUserProvider';
import FlashProvider from 'src/contexts/FlashProvider';
import SearchBarStateProvider from 'src/contexts/SearchBarStateProvider';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import ConfirmDialog from 'src/components/ConfirmDialog';
import Maintenance from 'src/components/Maintenance';
import Flash from 'src/components/Flash';
import CookieConsentBanner from 'src/components/CookieConsentBanner';
import SubscriptionEmailPopup from 'src/components/SubscriptionEmailPopup';
import CurrencyProvider from 'src/contexts/CurrencyProvider';
import FilterProvider from 'src/contexts/FilterProvider';
import OtherOptionCacheProvider from 'src/contexts/OtherOptionCacheProvider';
import TooManyRequestsErrorProvider from 'src/contexts/TooManyRequestsErrorProvider';
import TooManyRequestsErrorPopup from 'src/components/TooManyRequestsErrorPopup';
import ClearChatStates from 'src/components/chat/ClearChatStates';
import ModalLogin from 'src/components/login/Modal';
import ModalLoginProvider from 'src/contexts/ModalLoginProvider';
import Script from 'next/script';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { ApiRoutes } from 'src/lib/routes';
import GiftExperienceCodeProvider from 'src/contexts/GiftExperienceCodeProvider';
import PageViewTracker from 'src/components/PageViewTracker';
import pluralize from 'pluralize';
import CurrencySelectorProvider from 'src/contexts/CurrencySelectorProvider';
import BucketListProvider from 'src/contexts/BucketListProvider';
dayjs.locale('en-gb');
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(tz);
pluralize.addPluralRule('person', 'persons');
pluralize.addPluralRule('h', 'hrs');
pluralize.addPluralRule('min', 'mins');

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          key="metaviewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID} />
        <meta name="facebook-domain-verification" content="jpgl0vrefenqlvhyu4trgo56l13fi7" />
        <meta name="chrome" content="nointentdetection" />
        <meta name="format-detection" content="telephone=no, email=no, address=no" />
        <meta
          name="google-site-verification"
          content="tnxI--XKLheDu4nn5javHgWO1Z_FwKtQW61bVPuFAFU"
        />
        <link rel="dns-prefetch" href="https://d18sx48tl6nre5.cloudfront.net" />
        <link rel="dns-prefetch" href="https://js.stripe.com" />
        <link rel="dns-prefetch" href="https://connect.facebook.net" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="preload"
          href={ApiRoutes.apiInitialDataRoute({}).toUrl()}
          as="fetch"
          crossOrigin="anonymous"
        />
      </Head>

      {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
        <>
          <Script id="gtag-consent" strategy="beforeInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('consent', 'default', {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                ad_storage: 'denied',
                analytics_storage: 'denied',
                personalization_storage: 'denied',
                functionality_storage: 'denied',
                security_storage: 'denied',
                wait_for_update: 60000
              });
            `}
          </Script>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_GA4_TAG_ID}`}
          />
          <Script id="gtag" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_GA4_TAG_ID}', {
                send_page_view: false
              });
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADWORD_ID}', {
                send_page_view: false
              });
            `}
          </Script>
          <Script id="facebook" strategy="afterInteractive">
            {`
              !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return
                n = f.fbq = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                if (!f._fbq) f._fbq = n
                n.push = n
                n.loaded = !0
                n.version = '2.0'
                n.queue = []
                t = b.createElement(e)
                t.defer = true
                t.src = v
                s = b.getElementsByTagName(e)[0]
                s.parentNode.insertBefore(t, s)
              })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
              window.fbq('consent', 'revoke')
              window.fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}')
            `}
          </Script>
          <Script id="tiktok" strategy="afterInteractive">
            {`
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
          )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

                ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
              }(window, document, 'ttq');
            `}
          </Script>
        </>
      ) : null}

      <DefaultSeo
        dangerouslySetAllPagesToNoIndex={
          process.env.NEXT_PUBLIC_APP_ENV !== 'production' ? true : undefined
        }
        dangerouslySetAllPagesToNoFollow={
          process.env.NEXT_PUBLIC_APP_ENV !== 'production' ? true : undefined
        }
        titleTemplate="Seek Sophie | %s"
        title="Travel that makes life better"
        description="Asia's largest platform for trips in nature. Browse thousands of hikes, dives and safaris. Book directly with local independent businesses."
        openGraph={{
          siteName: 'Seek Sophie',
          type: 'website',
          images: [
            {
              url: imgLogo.src,
              height: 512,
              width: 512,
              alt: 'Seek Sophie',
            },
          ],
        }}
        twitter={{
          site: '@seeksophie',
          handle: '@seeksophie',
          cardType: 'summary_large_image',
        }}
      />
      <TooManyRequestsErrorProvider>
        <InitialDataProvider>
          <CurrencyProvider>
            <CurrentUserProvider>
              <ControlHeaderPageNavProvider>
                <FilterProvider>
                  <SearchBarStateProvider>
                    <ConfirmDialogProvider>
                      <FlashProvider>
                        <CartProvider>
                          <GiftExperienceCodeProvider>
                            <CurrencySelectorProvider>
                              <OtherOptionCacheProvider>
                                <BucketListProvider>
                                  <ModalLoginProvider>
                                    <Header />
                                    <ClearChatStates />
                                    <CookieConsentBanner />
                                    <Maintenance />
                                    <PageViewTracker />
                                    <div className="content">
                                      <Component {...pageProps} />
                                    </div>
                                    <Footer />
                                    <ConfirmDialog />
                                    <Flash />
                                    <SubscriptionEmailPopup />
                                    <TooManyRequestsErrorPopup />
                                    <ModalLogin />
                                  </ModalLoginProvider>
                                </BucketListProvider>
                              </OtherOptionCacheProvider>
                            </CurrencySelectorProvider>
                          </GiftExperienceCodeProvider>
                        </CartProvider>
                      </FlashProvider>
                    </ConfirmDialogProvider>
                  </SearchBarStateProvider>
                </FilterProvider>
              </ControlHeaderPageNavProvider>
            </CurrentUserProvider>
          </CurrencyProvider>
        </InitialDataProvider>
      </TooManyRequestsErrorProvider>
    </>
  );
}
export default MyApp;
