import { useState } from 'react';
import { AppRoutes } from 'src/lib/routes';
import { RiWhatsappFill } from 'react-icons/ri';
import { BsExclamationCircle } from 'react-icons/bs';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikProps } from 'formik';
import * as yup from 'yup';
import SocialLogin from 'src/components/SocialLogin';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import FormikObserver from 'src/components/FormikObserver';
import useCsrfTokenUpdate from 'src/hooks/useCsrfTokenUpdate';
import ApiClient from 'src/lib/api_client';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import WelcomeBack from 'src/components/login/WelcomeBack';
import InputPassword from 'src/components/InputPassword';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useFlash from 'src/hooks/useFlash';
import ChevronLeft from 'public/images/svg_icons/chevron_left.svg';
import { saveLastLoginMethod } from 'src/lib/last_login_method'
import Link from 'next/link';
import { User } from 'src/types';
import { getAfterLoginUrlObject, notifyLoggedInEvent } from 'src/helpers';
import WhatsappLogin from 'src/components/login/WhatsappLogin';
import { SignupForm } from 'src/pages/users/signup';
import useCart from 'src/hooks/useCart';
import styles from 'src/styles/modules/components/login.module.scss';

const EmailValidationSchema = yup.object().shape({
  email: yup.string()
    .email('Email is invalid')
    .required('Please enter email')
    .typeError('Please enter email')
});

const PasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Please enter your password').typeError('Please enter your password')
});

const EmailLogin: React.FC<{
  isEmailLoginPage?: boolean;
  lastLoginMethod?: User | undefined;
  loginRef?: React.MutableRefObject<boolean>;
  inlineRender?: boolean;
  title?: string;
}> = ({
  loginRef,
  isEmailLoginPage = true,
  lastLoginMethod: loginMethod,
  inlineRender,
  title = 'Log In or Sign Up'
}) => {
    const router = useRouter();
    const updateCsrfToken = useCsrfTokenUpdate();
    const [currentUser, setCurrentUser, saveToken, , isLoading] = useCurrentUser();
    const getLastLoginMethod = useEnhanceApiClient(ApiClient.getLastLoginMethod);
    const loginWithEmail = useEnhanceApiClient(ApiClient.loginWithEmail);
    const [lastLoginMethod, setLastLoginMethod] = useState<User | undefined>(loginMethod)
    const [error, setError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [inputEmail, setInputEmail] = useState<string>();
    const { code: invitationCode, osc: orderShareCode } = router.query
    const [loginType, setLoginType] = useState<'whatsapp' | 'email' | undefined>(undefined);
    const shouldRenderWhatsappLogin = inlineRender && loginType === 'whatsapp'
    const shouldRenderEmailLogin = inlineRender && loginType === 'email'
    const [cart] = useCart()
    const clear = (formik: FormikProps<{ password: string }>) => () => {
      saveLastLoginMethod(undefined)
      setLastLoginMethod(undefined)
      formik.resetForm()
    }

    return (
      <>
        {shouldRenderEmailLogin && inputEmail ? (
          <>
            <h2 className={styles.title}>Create Account</h2>
            <SignupForm inlineRender={inlineRender} inputEmail={inputEmail} />
          </>
        ) : shouldRenderWhatsappLogin ? (
          <WhatsappLogin loginRef={loginRef} setLoginType={setLoginType} inlineRender={inlineRender} title="Log In or Sign Up to book" />
        ) : lastLoginMethod ? (
          <Formik
            validationSchema={PasswordValidationSchema}
            initialValues={{ password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              updateCsrfToken()
                .then((token) => {
                  loginWithEmail({
                    user: {
                      email: lastLoginMethod.email,
                      password: values.password
                    },
                    code: invitationCode ? String(invitationCode) : '',
                    osc: orderShareCode ? String(orderShareCode) : '',
                    cartId: cart.id
                  }, { 'X-CSRF-Token': token })
                    .then((result) => {
                      const [json] = result
                      if ('error' in json) {
                        setError(json.error);
                      } else {
                        if (loginRef) {
                          loginRef.current = true;
                        }
                        saveToken(json.token);
                        setCurrentUser(json.user);
                        saveLastLoginMethod(json.user)
                        notifyLoggedInEvent(json.user)
                        if (!inlineRender) {
                          router.push(getAfterLoginUrlObject(router.query))
                        }
                      }
                    })
                    .catch((e) => {
                      setError('An internal error occurred');
                      throw e;
                    })
                    .finally(() => setSubmitting(false));
                })
                .catch((e) => {
                  setError(e.message);
                  throw e;
                });
            }}
          >
            {(formik) => {
              return (
                <div>
                  {['google_oauth2', 'facebook'].includes(String(lastLoginMethod.lastLoginMethod)) ? (
                    <div>
                      <WelcomeBack user={lastLoginMethod} isLoginPage={isEmailLoginPage} onClear={inlineRender ? clear(formik) : undefined} />
                      <SocialLogin
                        inlineRender={inlineRender}
                        emailError={error}
                        loginRef={loginRef}
                        shouldHideGoogle={lastLoginMethod.lastLoginMethod !== 'google_oauth2'}
                        shouldHideFacebook={lastLoginMethod.lastLoginMethod !== 'facebook'}
                      />
                      <div className="tw-text-brand-navy">
                        Not you? {' '}
                        <span onClick={clear(formik)}
                          className="tw-text-orange tw-underline tw-cursor-pointer tw-lowercase tw-font-styleSans tw-font-semibold"
                        >
                          Use another account
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Form>
                      {lastLoginMethod.id && isEmailLoginPage ?
                        <div className="form-group">
                          <div
                            className="tw-mb-6 tw-cursor-pointer"
                            onClick={clear(formik)}
                          >
                            <ChevronLeft width={24} height={24} />
                          </div>
                          <h2 className={styles.title}>Log In</h2>
                        </div>
                        :
                          <WelcomeBack user={lastLoginMethod} isLoginPage={isEmailLoginPage} onClear={inlineRender ? clear(formik) : undefined} />
                      }

                      <div className="form-group">
                        <InputPassword name="password" placeholder="Password" />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback tw-block"
                        />

                        <div className={classNames("invalid-feedback tw-block tw-text-sm", { "tw-hidden": !error })}>{error}</div>
                      </div>

                      <div className="tw-text-center tw-mt-4">
                        <button className="btn btn-primary" type="submit">Continue</button>
                      </div>

                      <div className={classNames('tw-mt-6 tw-mb-2 tw-text-center')}>
                        <Link legacyBehavior href={AppRoutes.forgotPasswordRoute({}).toUrl()} target="_blank">
                          <a className="tw-text-brand-navy tw-underline tw-cursor-pointer tw-font-styleSans tw-font-semibold tw-lowercase">Forgot password?</a>
                        </Link>
                      </div>

                      <div className={classNames('tw-text-brand-navy tw-text-center', { "tw-hidden": lastLoginMethod.id && isEmailLoginPage })}>
                        Not you? {' '}
                        <span onClick={clear(formik)}
                          className="tw-text-orange tw-underline tw-cursor-pointer tw-lowercase tw-font-styleSans tw-font-semibold"
                        >
                          Use another account
                        </span>
                      </div>
                    </Form>
                  )}
                </div>
              )
            }}
          </Formik>
        ) : (
          <Formik
            validationSchema={EmailValidationSchema}
            initialValues={{ email: '' }}
            onSubmit={(values, { setSubmitting }) => {
              getLastLoginMethod({ id: values.email })
                .then((result) => {
                  const [json] = result
                  if ('error' in json) {
                    setError(json.error);
                  } else {
                    if (json.user) {
                      if (json.user.isEmailOwnedByDiscardedUser) {
                        setError("Account deleted with owner’s permission. For recovery, contact chat@seeksophie.com");
                      } else if (json.user.shouldCreatePassword) {
                        setWarning(`You haven't set a password yet. We've sent a password creation link to ${values.email}`)
                      } else {
                        setLastLoginMethod(json.user)
                      }
                    } else {
                      if (inlineRender) {
                        setLoginType('email')
                        setInputEmail(values.email)
                      } else {
                        router.push(AppRoutes.signupRoute({}, { email: values.email, ...router.query }).toUrl())
                      }
                    }
                  }
                })
                .catch((e) => {
                  setError('An internal error occurred');
                  throw e;
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <FormikObserver
                    values={formik.values}
                    onChange={(values) => {
                      setError('')
                      setWarning('')
                    }}
                  />
                  <h2 className={styles.title}>{title}</h2>
                  <div className="form-group">
                    <div className="input-group">
                      <Field
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        autocomplete="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback tw-block"
                      />
                    </div>

                    {error && <div className={classNames('tw-mt-2 tw-text-red')}>{error}</div>}

                    <div className={classNames("tw-flex tw-text-sm tw-mt-2 align-items-center", { "tw-hidden": !warning })}>
                      <div><BsExclamationCircle width={24} height={24} className="tw-text-yellow mr-2" /></div>
                      <div>{warning}</div>
                    </div>
                  </div>

                  <div className="form-group tw-text-center">
                    <button type="submit" className="btn btn-primary">
                      Continue
                    </button>
                  </div>

                  <div className="tw-flex tw-items-center tw-pt-6 tw-pb-8">
                    <div className="tw-border-t tw-w-full tw-border-grey-lighter tw-border-solid" />
                    <div className="tw-px-3 tw-text-brand-navy">Or</div>
                    <div className="tw-border-t tw-w-full tw-border-grey-lighter tw-border-solid" />
                  </div>

                  <SocialLogin inlineRender={inlineRender} loginRef={loginRef} >
                    <div className="tw-mb-2">
                      <button
                        onClick={() => {
                          setLastLoginMethod(undefined)
                          saveLastLoginMethod(undefined)
                          if (inlineRender) {
                            setLoginType('whatsapp')
                          } else {
                            router.push(AppRoutes.loginWhatsappRoute({}, router.query).toUrl())
                          }
                        }}
                        type="button"
                        className="btn btn-block tw-bg-white tw-text-brand-navy tw-border tw-border-grey-lighter tw-border-solid tw-relative"
                      >
                        <RiWhatsappFill className="tw-absolute tw-left-3 tw-top-1 md:tw-top-2 tw-text-brand-navy" size={24} />
                        Continue with WhatsApp number
                      </button>
                    </div>
                  </SocialLogin>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    );
  };

export default EmailLogin;
