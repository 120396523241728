import { useRef, useState } from 'react';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { ApiRoutes, AppRoutes } from 'src/lib/routes';
import MailSvg from 'public/images/svg_icons/mail.svg';
import InfoSvg from 'public/images/svg_icons/info.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from 'src/lib/constants';
import { omit } from 'lodash-es';
import { useRouter } from 'next/router';
import WithDefaultJsonLd from 'src/components/WithDefaultJsonLd';
import useCsrfTokenUpdate from 'src/hooks/useCsrfTokenUpdate';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useRedirectIfLoggedIn from 'src/hooks/useRedirectIfLoggedIn';
import Loading from 'src/components/Loading';
import ApiClient from 'src/lib/api_client';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import InputPassword from 'src/components/InputPassword';
import classNames from 'classnames';
import useQuery from 'src/hooks/useQuery';
import { saveLastLoginMethod } from 'src/lib/last_login_method';
import useCart from 'src/hooks/useCart';
import { notifyLoggedInEvent } from 'src/helpers';

const SignupValidationSchema = yup.object().shape({
  name: yup.string().required('Please enter your name').typeError('Please enter your name'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter your email')
    .typeError('Please enter your email'),
  password: yup.string().required('Please enter password').typeError('Please enter password'),
  passwordConfirmation: yup
    .mixed()
    .test('match', 'Password confirmation does not match password', (value, context) => {
      return context.parent.password === value;
    }),
  [RECAPTCHA_KEY]: yup
    .string()
    .required('Please complete captcha challenge')
    .typeError('Please complete captcha challenge'),
});

type SignupFormProps = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  receiveNewletters: boolean;
  code?: string;
  [RECAPTCHA_KEY]: string;
};

export const SignupForm: React.FC<{ inlineRender?: boolean, inputEmail?: string }> = ({ inlineRender, inputEmail }) => {
  const signupAccount = useEnhanceApiClient(ApiClient.signupAccount);
  const [error, setError] = useState<string>();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const updateCsrfToken = useCsrfTokenUpdate();
  const loginRef = useRedirectIfLoggedIn();
  const router = useRouter();
  const [_, setCurrentUser, saveToken] = useCurrentUser();
  const newEmail = inputEmail || router.query.email
  const { code, osc: orderShareCode } = router.query
  const [cart] = useCart();

  const { data, isLoading: isLoadingInvatition } = useQuery<{ id: number; email: string }>(
    code ? ApiRoutes.apiSupplierInvitationRoute({ id: String(code) }).toUrl() : null
  );

  const { email: signUpEmail, id: invitationId } = data || {
    email: newEmail ? String(newEmail) : '',
  };


  return isLoadingInvatition ? (
    <Loading className="tw-justify-center tw-pt-20" />
  ) : (
    <Formik<SignupFormProps>
      validationSchema={SignupValidationSchema}
      initialValues={{
        name: '',
        email: signUpEmail || '',
        password: '',
        passwordConfirmation: '',
        receiveNewletters: true,
        code: code ? String(code) : '',
        [RECAPTCHA_KEY]: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        updateCsrfToken()
          .then((token) => {
            signupAccount(
              {
                user: omit(values, [RECAPTCHA_KEY, 'code']),
                [RECAPTCHA_KEY]: values[RECAPTCHA_KEY],
                code: values.code,
                osc: orderShareCode ? String(orderShareCode) : '',
                cartId: cart.id
              },
              { 'X-CSRF-Token': token }
            )
              .then((result) => {
                const [json] = result;
                if ('error' in json) {
                  setError(json.error);
                } else {
                  loginRef.current = true;
                  saveToken(json.token);
                  setCurrentUser(json.user);
                  notifyLoggedInEvent(json.user)
                  saveLastLoginMethod(json.user);
                  if (!inlineRender) {
                    window.location.href = AppRoutes.accountPhoneNumberRoute(
                      {},
                      { add_phone: true, ...router.query }
                    ).toUrl();
                  }
                }
              })
              .catch((e) => {
                setError('An internal error occurred');
                throw e;
              })
              .finally(() => setSubmitting(false));
          })
          .catch((e) => {
            setError(e.message);
            throw e;
          });
      }}
    >
      {(formik) => {
        return (
          <Form>
            <div className="form-group">
              <div className="input-group">
                <Field
                  name="name"
                  placeholder="Name"
                  className="form-control"
                  autocomplete="name"
                />
              </div>
              <ErrorMessage
                name="name"
                component="div"
                className="invalid-feedback tw-block"
              />
            </div>
            <div className="form-group">
              <div className="input-group">
                <Field
                  disabled={invitationId ? true : false}
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  autocomplete="email"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback tw-block"
              />
            </div>

            <div className="form-group">
              <InputPassword name="password" placeholder="Password" />

              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback tw-block"
              />

              <div className="tw-text-sm tw-text-grey tw-mt-2">
                Minimum 6 characters for password.
              </div>
            </div>

            <div className="form-group">
              <InputPassword name="passwordConfirmation" placeholder="Confirm Password" />

              <ErrorMessage
                name="passwordConfirmation"
                component="div"
                className="invalid-feedback tw-block"
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkbox-receive-newletters"
                  checked={formik.values.receiveNewletters}
                  onChange={(e) => {
                    formik.setFieldValue('receiveNewletters', e.target.checked);
                  }}
                />
                <label
                  className="form-check-label tw-text-sm form-check-sm"
                  htmlFor="checkbox-receive-newletters"
                >
                  Yes I’d like emails with uncommon travel ideas please!
                </label>
              </div>
            </div>

            <div className="mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
                onChange={() => {
                  if (recaptchaRef.current) {
                    const recaptchaValue = recaptchaRef.current.getValue();
                    formik.setFieldValue(RECAPTCHA_KEY, recaptchaValue);
                  }
                }}
              />
              <ErrorMessage
                name={RECAPTCHA_KEY}
                component="div"
                className="invalid-feedback tw-block"
              />
              <div
                className={classNames('invalid-feedback tw-block', { 'tw-hidden': !error })}
              >
                {error}
              </div>
            </div>

            <div className="form-group tw-text-center">
              <button type="submit" className="btn btn-primary">
                Continue
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

const Page = () => {
  const [currentUser, _, __, , isLoading] = useCurrentUser();

  return isLoading || currentUser ? (
    <Loading className="tw-justify-center tw-py-20" />
  ) : (
    <>
      <NextSeo
        title="Sign up for Seek Sophie"
        description="Probably the friendliest place to plan & book Outdoor, Wildlife and Eco-tourism activities"
        canonical={AppRoutes.signupRoute({}).toUrl()}
        openGraph={{
          title: 'Sign up for Seek Sophie',
          description:
            'Probably the friendliest place to plan & book Outdoor, Wildlife and Eco-tourism activities',
          url: AppRoutes.signupRoute({}).toUrl(),
        }}
      />
      <WithDefaultJsonLd />

      <div
        className="tw-bg-brand-yellow tw-py-16 md:tw-py-24"
        style={{ minHeight: 'calc(100vh - 422px)' }}
      >
        <div className="container tw-max-w-md">
          <h2 className="tw-text-2xl font-weight-bold text-center mb-4">Create an account</h2>

          <SignupForm />
        </div>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
  };
};

export default Page;
