import React, { useRef, useEffect, MutableRefObject } from 'react'
import intlTelInput, { Iti } from 'intl-tel-input'
import { useField } from 'formik'

type Props = {
  name: string;
} & React.ComponentPropsWithoutRef<'input'> & {
  onChange?: (intlTelInput?: Iti) => void;
}

const InputPhoneNumber: React.FC<Props> = ({ name, className = 'form-control', onChange, ...rest }) => {
  const [field, , helper] = useField(name)
  const ele = useRef<HTMLInputElement>(null)
  const intlTel = useRef<Iti>()

  useEffect(() => {
    if (ele.current) {
      intlTel.current = intlTelInput(ele.current, {
        containerClass: 'tw-w-full',
        countryOrder: ['SG', 'VN', 'MY'],
        nationalMode: false,
        // @ts-ignore
        loadUtils: () => import('intl-tel-input/utils'),
        formatOnDisplay: false
      })
    }
  }, [])

  return (
    <input
      ref={ele}
      type="text"
      className={className}
      {...field}
      onInput={() => {
        helper.setValue(intlTel.current?.getNumber())
      }}
      onBlur={(event) => {
        helper.setValue(intlTel.current?.getNumber())
      }}
      {...rest}
    />
  )
}

export default InputPhoneNumber
