import React, { useEffect } from 'react';

function FormikObserver({
  values,
  onChange,
}: {
  values: Record<string, any>;
  onChange: (values: Record<string, any>) => void;
}) {
  useEffect(() => {
    onChange(values);
  }, [JSON.stringify(values)]);

  return <></>;
}

export default FormikObserver;
