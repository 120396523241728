import { User } from 'src/types';
import Image from 'next/image';
import ImageWithFallback from 'src/components/ImageWithFallback';
import DefaultProfileSvg from 'public/images/svg_icons/default_profile.svg';

type Props = {
  currentUser?: User;
  imageDataUrl?: string | null;
  className?: string;
  borderClassName?: string;
}

const UserAvatar: React.FC<Props> = ({ imageDataUrl, currentUser, className, borderClassName = 'tw-border-2 tw-border-brand-green tw-border-solid' }) => {
  return (
    imageDataUrl ? (
      <div className={`${className || 'tw-h-12 tw-w-12'} tw-relative`}>
        <Image src={imageDataUrl} alt="" fill className={`tw-object-cover tw-rounded-full ${borderClassName}`} />
      </div>
    ) : currentUser?.avatar ? (
      <div className={`${className || 'tw-h-12 tw-w-12'} tw-relative`}>
        <ImageWithFallback record={currentUser.avatar} imgField="webpSmUrl" alt="" fill className={`tw-object-cover tw-rounded-full ${borderClassName}`} />
      </div>
    ) : (
      <DefaultProfileSvg className={`tw-text-brand-navy tw-rounded-full ${className || 'tw-h-12 tw-w-12'} ${borderClassName}`} />
    )
  )
}

export default UserAvatar;
