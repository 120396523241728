import EmailLogin from 'src/components/login/EmailLogin';
import { Modal } from 'react-bootstrap'
import useModalLogin from 'src/hooks/useModalLogin';
import { FiX } from 'react-icons/fi';

const ModalLogin: React.FC = () => {
  const { show, setShow } = useModalLogin()

  return (
    <Modal
      show={show}
      backdrop="static"
      className="modal-styled modal-styled-sm"
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header>
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <div className="">
          </div>
          <div className="tw-ml-auto">
            <FiX
              size={24}
              className="tw-cursor-pointer"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="inline-login">
          <EmailLogin inlineRender={true} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalLogin
