import { CardNetwork, User } from 'src/types';
import SvgCard from 'public/images/svg_icons/credit_card.svg';
import SvgVisa from 'public/images/svg_icons/visa.svg';
import SvgMastercard from 'public/images/svg_icons/mastercard.svg';
import SvgAmex from 'public/images/svg_icons/amex.svg';
import SvgUnionpay from 'public/images/svg_icons/unionpay.svg';
import SvgDiscover from 'public/images/svg_icons/discover.svg';
import SvgDiners from 'public/images/svg_icons/diners.svg';
import SvgJcb from 'public/images/svg_icons/jcb.svg';

export const getCardNetworkIcons: (width: number, height: number, className?: string) => { [key in CardNetwork]: any } =
  (width, height, className) => {
    return {
      visa: <SvgVisa height={width} width={height} className={className} />,
      mastercard: <SvgMastercard height={width} width={height} className={className} />,
      amex: <SvgAmex height={width} width={height} className={className} />,
      diners: <SvgDiners height={width} width={height} className={className} />,
      discover: <SvgDiscover height={width} width={height} className={className} />,
      jcb: <SvgJcb height={width} width={height} className={className} />,
      unionpay: <SvgUnionpay height={width} width={height} className={className} />,
      eftpos_au: <SvgCard height={width} width={height} className={className} />,
    };
  };

export const expandInputHeight = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
  const target = event.target;
  if (!(target instanceof HTMLTextAreaElement)) return;
  const tmpTextArea = document.querySelector('#tmp-textarea') as HTMLTextAreaElement
  tmpTextArea.className = target.className
  tmpTextArea.rows = target.rows
  tmpTextArea.value = target.value
  tmpTextArea.style.width = `${target.clientWidth}px`
  const borderWidth = target.offsetHeight - target.clientHeight;
  target.style.height = `${tmpTextArea.scrollHeight + borderWidth}px`;
};

export const getExpandInputHeight = (target: HTMLElement, options: { className: string; rows: number; value: string; }) => {
  const tmpTextArea = document.querySelector('#tmp-textarea') as HTMLTextAreaElement
  tmpTextArea.className = options.className
  tmpTextArea.rows = options.rows
  tmpTextArea.value = options.value
  tmpTextArea.style.width = `${target.clientWidth}px`
  const borderWidth = target.offsetHeight - target.clientHeight;
  return tmpTextArea.scrollHeight + borderWidth
}

export const notifyLoggedInEvent = (user: User) => {
  window.document.dispatchEvent(new CustomEvent('ss:logged_in', { detail: user }))
}
