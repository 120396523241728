import {
  BookingFormValues,
  Option,
  TMoney,
  EnumOptionKind,
  Tag,
  EnumPriceUnit,
  ICurrency,
  Currency,
  Country,
  Image,
} from 'src/types';
import { numericFormatter } from 'react-number-format';
import currencies from 'src/data/currencies';
import { MasterCurrency, DBDateFormat } from 'src/lib/constants';
import dayjs from 'dayjs';
export * from './order_item';
export * from './account';
export * from './env';
export * from './image';
export * from './option';
export * from './chat';
export * from './pricing';
export * from './formik';
export * from './ui';
import { upperFirst, snakeCase, min } from 'lodash-es';
import Cookies from 'js-cookie';
import { StaticImageData } from 'next/image';
import { ParsedUrlQuery } from 'querystring';
import { AppRoutes } from 'src/lib/routes';
import pluralize from 'pluralize';
import intlTelInput from 'intl-tel-input';
import InputDatePicker from 'src/components/InputDatePicker';

export function humanize(str: string) {
  return upperFirst(snakeCase(str).replace(/_/g, ' '))
}

export function humanizeMoney(amount: TMoney): string {
  const currency = currencies.find((o) => amount.currency === o.code);

  if (!currency) {
    throw Error(`${amount.currency} is not supported`);
  }

  return numericFormatter(String(amount.amount), {
    thousandSeparator: currency.thousandsSeparator,
    decimalSeparator: currency.decimalMark,
    decimalScale: Number(currency.exponent),
    prefix: currency.prefix ? currency.prefix : '',
    suffix: currency.suffix ? currency.suffix : '',
  });
}

export function getEleOffset(el: Element) {
  const box = el.getBoundingClientRect();
  const docElem = document.documentElement;
  return {
    top: box.top + window.pageYOffset - docElem.clientTop,
    left: box.left + window.pageXOffset - docElem.clientLeft,
  };
}

export function scrollTo(el: Element, adjustDistance: number = 0) {
  const offset = getEleOffset(el);
  window.scrollTo({
    left: 0,
    top: offset.top + adjustDistance,
    behavior: 'smooth',
  });
}

export const scrollToEle = (selector: string, adjustDistance?: number) => {
  return () => {
    const ele = document.querySelector(selector);
    if (ele instanceof HTMLElement) {
      scrollTo(ele, adjustDistance || 0);
    }
  };
};

export function displayPriceUnit(option: Option, priceUnit?: string) {
  return priceUnit
    ? priceUnit
    : option.kind === EnumOptionKind.stay
    ? '/night'
    : option.kind === EnumOptionKind.flexibleAdventure
    ? '/day'
    : option.priceUnit === EnumPriceUnit.perGroup
    ? '/group'
    : option.priceUnit === EnumPriceUnit.perRoom
    ? '/room'
    : '/person';
}

export function getMinToHours(totalMinutes: number) {
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return hours > 0 && minutes > 0
    ? `${pluralize('h', hours, true)}+`
    : minutes > 0
    ? pluralize('min', minutes, true)
    : hours > 0
    ? pluralize('h', hours, true)
    : null;
}

export const tagWithSuffix = (tag: Tag, familyWithKidTagId: number | undefined, minAge: number) => {
  if (tag.id === familyWithKidTagId)
    return `${tag.name} ${minAge === 0 ? '(No Min Age)' : `(Min Age ${minAge})`}`;
  return tag.name;
};

export const getNumberOfWeekdaysBetweenDates = (dayOfWeek: number, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  let numberOfWeeks = Math.abs(startDate.diff(endDate, "week"));
  const numberOfDays = Math.abs(startDate.diff(endDate, "day"));
  const remainDays = numberOfDays - numberOfWeeks * 7;
  let diff = endDate.day() - dayOfWeek;
  if (diff < 0) diff += 7;
  if (remainDays >= diff) numberOfWeeks += 1;
  return numberOfWeeks;
}

export const generateArrayDates = (
  from: string | Date,
  to: string | Date,
  weekdays?: number[] | null
) => {
  var ary = [];
  var currentDate = dayjs(from);
  var endDate = dayjs(to);
  var maxEndDate = dayjs().add(1, 'year').endOf('year');
  endDate = endDate.isSameOrBefore(maxEndDate) ? endDate : maxEndDate;
  var parsedWeekdays = weekdays && weekdays.map((v) => Number(v));
  while (currentDate.isSameOrBefore(endDate)) {
    if (!parsedWeekdays || parsedWeekdays.includes(currentDate.day())) {
      ary.push(currentDate.format(DBDateFormat));
    }
    currentDate = currentDate.add(1, 'day');
  }
  return ary;
};

export function isStayOrFlexible(option: Option) {
  return [EnumOptionKind.flexibleAdventure, EnumOptionKind.stay].includes(
    option.kind as EnumOptionKind
  );
}

export function isValidPhoneNumber(number: string, inputEle?: HTMLInputElement) {
  const instance = inputEle ? intlTelInput.getInstance(inputEle) : undefined
  return instance
    ? instance.isValidNumber() || false
    : !!number;
}

export function displayReadTime(readTime?: number) {
  return readTime && readTime > 0 ? `- ${readTime} min read` : '- Less than 1 min read';
}

export function getCsrfToken() {
  return Cookies.get('next_csrf_token');
}

export function convertStaticImageToUploadImage(image: StaticImageData, name?: string): Image {
  return {
    id: 0,
    name: name || 'default',
    contentType: 'image/jpg',
    caption: undefined,
    url: image.src,
    xlUrl: image.src,
    lgUrl: image.src,
    mdUrl: image.src,
    smUrl: image.src,
    webpXlUrl: image.src,
    webpLgUrl: image.src,
    webpMdUrl: image.src,
    webpSmUrl: image.src,
    blurUrl: image.src,
    blurBase64: '',
  };
}

export function getSelectedDates(bookingFormValues: BookingFormValues, option: Option) {
  if (bookingFormValues.startDate && bookingFormValues.endDate) {
    return generateArrayDates(bookingFormValues.startDate, bookingFormValues.endDate).slice(
      0,
      option.kind === EnumOptionKind.flexibleAdventure ? undefined : -1
    );
  } else if (bookingFormValues.startDate) {
    return generateArrayDates(bookingFormValues.startDate, bookingFormValues.startDate);
  } else {
    return [];
  }
}

export function getAfterLoginUrlObject(routerQuery: ParsedUrlQuery, defaultUrl: string = AppRoutes.rootRoute({}).toPath()) {
  const { redirect_path: redirectPath, redirect_path_query: redirectPathQuery } = routerQuery

  let query: Record<string, any> = {}
  try {
    query = JSON.parse(String(redirectPathQuery))
  } catch {
  }
  return redirectPath ? {
    pathname: String(redirectPath),
    query: query
  } : {
    pathname: defaultUrl
  }
}

export function isProduction() {
  return process.env.NEXT_PUBLIC_APP_ENV === 'production'
}

export function getFacebookShareUrl(url: string, redirectUrl: string, isMobile: boolean = false) {
  return isMobile ? `fb-messenger://share/?link=${url}` : `
    http://www.facebook.com/dialog/send?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&link=${url}&redirect_uri=${encodeURIComponent(redirectUrl)}
  `
}
