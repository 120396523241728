import React, { useContext } from 'react';
import Link from 'next/link';
import { AppRoutes } from 'src/lib/routes';
import SubscribeNewsletter from './SubscribeNewsletter';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import FooterImg1 from 'public/images/illu_1.png';
import FooterImg2 from 'public/images/illu_2.png';
import FooterImg3 from 'public/images/illu_3.png';
import FooterImg4 from 'public/images/illu_4.png';
import FooterImg5 from 'public/images/illu_5.png';
import ImageWithFallback from './ImageWithFallback';
import { ControlHeaderPageNavContext } from 'src/contexts/ControlHeaderPageNavProvider';

const Footer: React.FC = () => {
  const { showFooter } = useContext(ControlHeaderPageNavContext)
  const year = new Date().getFullYear();
  return showFooter ? (
    <div className="tw-bg-brand-green tw-py-16 lg:tw-py-24">
      <div className="container tw-mb-8">
        <div className="tw-flex tw-justify-between tw-items-end">
          <div className="tw-flex-shrink-0 tw-w-18 lg:tw-w-40">
            <ImageWithFallback src={FooterImg1} className="tw-static tw-block tw-w-full tw-h-auto tw-object-cover" alt="" />
          </div>
          <div className="tw-flex-shrink-0 tw-w-44 tw-hidden lg:tw-block">
            <ImageWithFallback src={FooterImg2} className="tw-static tw-block tw-w-full tw-h-auto tw-object-cover" alt="" />
          </div>
          <div className="tw-flex-shrink-0 tw-w-24 lg:tw-w-44">
            <ImageWithFallback src={FooterImg3} className="tw-static tw-block tw-w-full tw-h-auto tw-object-cover" alt="" />
          </div>
          <div className="tw-flex-shrink-0 tw-w-16 lg:tw-w-32">
            <ImageWithFallback src={FooterImg4} className="tw-static tw-block tw-w-full tw-h-auto tw-object-cover" alt="" />
          </div>
          <div className="tw-flex-shrink-0 tw-w-40 tw-hidden lg:tw-block">
            <ImageWithFallback src={FooterImg5} className="tw-static tw-block tw-w-full tw-h-auto tw-object-cover" alt="" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-5 tw-text-center lg:tw-text-left">
            <div className="tw-text-4xl tw-font-styleSans tw-font-semibold tw-mb-2 lg:tw-mb-4 tw-leading-none">So.. who's Sophie?</div>
            <div className="tw-text-xs md:tw-text-base">
              We get asked that a lot! There’s no one on the team actually named Sophie. Sophie means wisdom in Greek.  We wanted Seek Sophie to be about travel that helps you discover more about yourself and the world—travel that fills your heart and leaves you feeling more connected.
            </div>

            <div className="tw-my-8 tw-border-brand-navy tw-border-t lg:tw-hidden"></div>
          </div>
          <div className="offset-lg-1 col-lg-6">
            <div className="row tw-mb-8">
              <div className="col-6 col-lg-4 tw-mb-4 lg:tw-mb-0">
                <div className="tw-text-xl tw-font-semibold tw-font-styleSans tw-leading-none tw-mb-2">About us</div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.aboutRoute().toPath()}>
                    <a aria-label="Our Story" className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none">
                      Our Story
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.ourMissionRoute().toPath()}>
                    <a
                      aria-label="Travel as a Force for Good"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Our Mission
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.ourCurationRoute().toPath()}>
                    <a
                      aria-label="Curation process"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Curation process
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.sustainabilityRoute().toPath()}>
                    <a
                      aria-label="Curation process"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Sustainability 101
                    </a>
                  </Link>
                </div>
                <div className="tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.customerReviewsRoute().toPath()}>
                    <a
                      aria-label="Curation process"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Reviews
                    </a>
                  </Link>
                </div>
              </div>

              <div className="col-6 col-lg-4 tw-mb-4 lg:tw-mb-0">
                <div className="tw-text-xl tw-font-semibold tw-font-styleSans tw-mb-2 tw-leading-none">Discover more</div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.corporateRoute().toPath()}>
                    <a aria-label="Corporate" className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none">
                      Corporate Experiences
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.inviteRoute().toPath()}>
                    <a
                      aria-label="Invite a friend"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Invite a Friend
                    </a>
                  </Link>
                </div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.promotionsRoute().toPath()}>
                    <a
                      aria-label="Current Promotions"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Current Promotions
                    </a>
                  </Link>
                </div>
              </div>

              <div className="col-6 col-lg-4">
                <div className="tw-text-xl tw-font-semibold tw-font-styleSans tw-mb-2 tw-leading-none">Get in touch</div>
                <div className="tw-mb-1 lg:tw-mb-2 tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.contactRoute().toPath()}>
                    <a aria-label="Contact Us" className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none">
                      Contact Us
                    </a>
                  </Link>
                </div>
                <div className="tw-leading-none">
                  <Link legacyBehavior href={AppRoutes.creatorsCommunityRoute().toPath()}>
                    <a
                      aria-label="Join Seek Sophie Creators"
                      className="hover:tw-underline tw-text-brand-navy tw-text-xs md:tw-text-base tw-leading-none"
                    >
                      Join Seek Sophie Creators
                    </a>
                  </Link>
                </div>
              </div>

              <div className="col-6 lg:tw-hidden">
                <div className="tw-flex">
                  <a
                    href="https://www.facebook.com/seeksophie"
                    className="tw-mr-2"
                    aria-label="Follow us on Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsFacebook size={28} className="tw-text-brand-navy" />
                  </a>
                  <a
                    href="https://www.instagram.com/seeksophie/"
                    aria-label="Follow us on Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="tw-h-7 tw-w-7 tw-rounded-full tw-bg-brand-navy tw-p-1.5 tw-text-center">
                      <BsInstagram size={16} className="tw-text-brand-green tw-leading-7 tw-align-top" />
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="tw-my-8 tw-border-brand-navy tw-border-t lg:tw-hidden"></div>

            <div>
              <div className="tw-mb-4 lg:tw-mb-8 tw-text-center lg:tw-text-left">
                <div className="tw-text-lg lg:tw-text-xl tw-font-styleSans tw-font-semibold tw-mb-2 tw-leading-none">Let’s be friends!</div>
                <div className="tw-text-xs md:tw-text-base tw-leading-none">
                  Get the scoop on secret spots and hidden gems delivered straight to your inbox.
                </div>
              </div>

              <div className="tw-flex tw-justify-center lg:tw-justify-start tw-items-start">
                <SubscribeNewsletter />
                <div className="tw-hidden lg:tw-flex tw-ml-7 tw-pt-4">
                  <a
                    href="https://www.facebook.com/seeksophie"
                    className="tw-mr-2"
                    aria-label="Follow us on Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsFacebook size={28} className="tw-text-brand-navy" />
                  </a>
                  <a
                    href="https://www.instagram.com/seeksophie/"
                    aria-label="Follow us on Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="tw-h-7 tw-w-7 tw-rounded-full tw-bg-brand-navy tw-p-1.5 tw-text-center">
                      <BsInstagram size={16} className="tw-text-brand-green tw-leading-7 tw-align-top" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container tw-mt-4 lg:tw-mt-0">
        <div className="tw-flex tw-justify-center lg:tw-justify-start">
          <span className="tw-text-sm tw-font-semibold">&#169; Seek Sophie {year}</span>
          <Link legacyBehavior href={AppRoutes.privacyRoute().toPath()} aria-label="Privacy Policy">
            <a className="hover:tw-underline tw-text-brand-navy tw-text-sm tw-font-semibold tw-ml-4">Privacy</a>
          </Link>
          <Link legacyBehavior href={AppRoutes.termsRoute().toPath()} aria-label="Term of Conditions">
            <a className="hover:tw-underline tw-text-brand-navy tw-text-sm tw-font-semibold tw-ml-4">Terms</a>
          </Link>
        </div>
        <div className='tw-text-xs tw-text-center md:tw-text-left tw-mt-1'>Travel Agent Licence TA03435</div>
      </div>
    </div>
  ) : null;
};

export default Footer;
