import { useRef, useEffect, useState } from 'react';
import Script from 'next/script';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useCsrfTokenUpdate from 'src/hooks/useCsrfTokenUpdate';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import ApiClient from 'src/lib/api_client';
import { saveLastLoginMethod } from 'src/lib/last_login_method'
import { BsFacebook, BsGoogle } from 'react-icons/bs';
import { getAfterLoginUrlObject, notifyLoggedInEvent } from 'src/helpers';
import useCart from 'src/hooks/useCart';

type Props = {
  emailError?: string;
  loginRef?: React.MutableRefObject<boolean>;
  shouldHideFacebook?: boolean;
  shouldHideGoogle?: boolean;
  inlineRender?: boolean;
};

const SocialLogin: React.ReactFCWithChildren<Props> = ({ emailError, loginRef, shouldHideFacebook, shouldHideGoogle, inlineRender, children }) => {
  const loginWithFacebook = useEnhanceApiClient(ApiClient.loginWithFacebook);
  const loginWithGoogle = useEnhanceApiClient(ApiClient.loginWithGoogle);
  const [, setCurrentUser, saveToken] = useCurrentUser();
  const router = useRouter();
  const [error, setError] = useState<string>();
  const [activation, setActivation] = useState({
    facebook: false,
    google: false,
  });
  const googleAuthRef = useRef<{ grantOfflineAccess: () => Promise<any> }>();
  const updateCsrfToken = useCsrfTokenUpdate();
  const [cart] = useCart()
  const { osc: orderShareCode } = router.query

  const googleSignInCallback = (response: { code?: string }) => {
    if (response.code) {
      updateCsrfToken()
        .then((token) => {
          loginWithGoogle({ code: response.code, cartId: cart.id, osc: String(orderShareCode) }, { 'X-CSRF-Token': token })
            .then((result) => {
              const [json] = result
              if ('error' in json) {
                setError(json.error);
              } else {
                if (loginRef) {
                  loginRef.current = true;
                }
                saveToken(json.token);
                setCurrentUser(json.user);
                notifyLoggedInEvent(json.user)
                saveLastLoginMethod(json.user)
                if (!inlineRender) {
                  router.push(getAfterLoginUrlObject(router.query))
                }
              }
            })
            .catch((e) => {
              setError('An internal error occurred');
              throw e;
            });
        })
        .catch((e) => {
          setError(e.message);
          throw e;
        });
    } else {
      setError('Unable to connect with Google');
    }
  };

  const facebookSignInCallback = (response: { status?: string }) => {
    if (response.status === 'connected') {
      updateCsrfToken()
        .then((token) => {
          loginWithFacebook({ cartId: cart.id, osc: String(orderShareCode) }, { 'X-CSRF-Token': token }).then((result) => {
            const [json] = result
            if ('error' in json) {
              setError(json.error);
            } else {
              if (loginRef) {
                loginRef.current = true;
              }
              saveToken(json.token);
              setCurrentUser(json.user);
              notifyLoggedInEvent(json.user)
              saveLastLoginMethod(json.user)
              if (!inlineRender) {
                router.push(getAfterLoginUrlObject(router.query))
              }
            }
          });
        })
        .catch((e) => {
          setError('An internal error occurred');
          throw e;
        });
    } else {
      setError('Unable to connect with Facebook');
    }
  };

  useEffect(() => {
    if (emailError) {
      setError(emailError);
    }
  }, [emailError]);

  useEffect(() => {
    if (window.gapi) {
      setActivation((prev) => ({
        ...prev,
        google: true,
      }));
    }

    if (window.FB) {
      setActivation((prev) => ({
        ...prev,
        facebook: true,
      }));
    }
  }, []);

  return (
    <>
      <Script
        src="https://apis.google.com/js/client:platform.js"
        onReady={() => {
          if (window.gapi) {
            const gapi = window.gapi;
            gapi.load('auth2', function () {
              googleAuthRef.current = gapi.auth2.init({
                client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
              });
              setActivation((prev) => ({
                ...prev,
                google: true,
              }));
            });
          }
        }}
      />
      <Script
        src="https://connect.facebook.net/en_US/sdk.js"
        onReady={() => {
          if (window.FB) {
            window.FB.init({
              appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
              cookie: true,
              xfbml: true,
              version: 'v2.7',
            });
            setActivation((prev) => ({
              ...prev,
              facebook: true,
            }));
          }
        }}
      />

      <div className={classNames('tw-mb-2', { 'tw-hidden': shouldHideFacebook })}>
        <button
          disabled={!activation.facebook}
          onClick={() => {
            if (window?.FB?.login) {
              window.FB.login(facebookSignInCallback, { scope: 'public_profile,email' });
            }
          }}
          type="button"
          className="btn btn-block tw-bg-white tw-text-brand-navy tw-border tw-border-grey-lighter tw-border-solid tw-relative"
        >
          <BsFacebook className="tw-text-brand-navy tw-absolute tw-left-3 tw-top-1 md:tw-top-2" size={24} />
          Continue with Facebook
        </button>
      </div>

      <div className={classNames('tw-mb-2', { 'tw-hidden': shouldHideGoogle })}>
        <button
          disabled={!activation.google}
          onClick={() => {
            if (googleAuthRef.current) {
              googleAuthRef.current.grantOfflineAccess().then(googleSignInCallback);
            }
          }}
          type="button"
          className="btn btn-block tw-bg-white tw-text-brand-navy tw-border tw-border-grey-lighter tw-border-solid tw-relative"
        >
          <BsGoogle className="tw-absolute tw-left-3 tw-top-1 md:tw-top-2 tw-text-brand-navy" size={24} />
          Continue with Google
        </button>
      </div>

      {children}

      {error && <div className={classNames('tw-mb-4 tw-text-red')}>{error}</div>}
    </>
  );
};

export default SocialLogin;
