import { flatten } from 'lodash-es';
import React, { useState } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { BucketListOption, BucketList } from 'src/types';

type BucketListProps = {
  mutate: () => void;
  bucketLists: BucketList[];
  bucketListOptions: BucketListOption[];
  setDeletedBucketListOption: React.Dispatch<React.SetStateAction<BucketListOption | undefined>>;
  deletedBucketListOption?: BucketListOption;
};

export const BucketListContext = React.createContext<BucketListProps>({
  mutate: () => {},
  bucketLists: [],
  bucketListOptions: [],
  setDeletedBucketListOption: () => {},
  deletedBucketListOption: undefined,
});
const BucketListProvider: React.ReactFCWithChildren = ({ children }) => {
  const [currentUser, mutate] = useCurrentUser();
  const bucketLists = currentUser?.bucketLists || [];
  const bucketListOptions = flatten(bucketLists.map((o) => o.bucketListOptions || []));
  const [deletedBucketListOption, setDeletedBucketListOption] = useState<
    BucketListOption | undefined
  >(undefined);

  return (
    <BucketListContext.Provider
      value={{
        mutate,
        bucketListOptions,
        bucketLists,
        deletedBucketListOption,
        setDeletedBucketListOption,
      }}
    >
      {children}
    </BucketListContext.Provider>
  );
};

export default BucketListProvider;
