import { useEffect, useRef } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { AppRoutes } from 'src/lib/routes';
import { useRouter } from 'next/router';

const useRedirectIfLoggedIn = () => {
  const loginRef = useRef<boolean>(false)
  const router = useRouter();
  const [currentUser, , , , isLoading] = useCurrentUser();
  const { redirect_path: redirectPath } = router.query

  useEffect(() => {
    if (!isLoading && currentUser?.id && !loginRef.current) {
      window.location.href = redirectPath ? String(redirectPath) : AppRoutes.rootRoute({}).toUrl()
    }
  }, [isLoading, currentUser?.id])

  return loginRef
}

export default useRedirectIfLoggedIn
