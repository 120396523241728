import { ReactFCWithChildren, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import FormikObserver from 'src/components/FormikObserver';
import InputPhoneNumber from 'src/components/InputPhoneNumber';
import ChevronLeft from 'public/images/svg_icons/chevron_left.svg';
import Countdown from 'src/components/Countdown';
import classNames from 'classnames';
import WelcomeBack from 'src/components/login/WelcomeBack';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import { isValidPhoneNumber } from 'src/helpers';
import ApiClient from 'src/lib/api_client';
import useCurrentUser from 'src/hooks/useCurrentUser';
import dayjs from 'dayjs';
import { User } from 'src/types';
import styles from 'src/styles/modules/components/login.module.scss';

const CodeValidationSchema = yup.object().shape({
  code: yup.string().required('Please enter your code').typeError('Please enter your code'),
});

const PhoneNumberValidationSchema = yup.object().shape({
  phoneNumber: yup.mixed().test('format', 'Please input a valid phone number', (phoneNumber) => {
    const inputEle = document.querySelector('input[name="phoneNumber"]')
    return isValidPhoneNumber(phoneNumber, inputEle ? inputEle as HTMLInputElement : undefined);
  }),
});

type Props = {
  inlineRender?: boolean;
  user?: User;
  description?: string;
  submitTitle: string;
  title?: string;
  phoneNumber?: string;
  isLoginPage?: boolean;
  onVerifyPhoneNumber?: (
    code: string,
    phoneNumber: string,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => void;
};

type WhatasppPhoneNumberVerificationFormProps = {
  submitTitle: string;
  title?: string;
  expiredTime: string;
  phoneNumber: string;
  isLoginPage?: boolean;
  setFormType: React.Dispatch<React.SetStateAction<'phone_number' | 'verification'>>
  onResendCode: (expiredTime: string) => void;
  onVerifyPhoneNumber: (
    code: string,
    phoneNumber: string,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => void;
};

type WhatsappPhoneNumbeFormProps = {
  inlineRender?: boolean;
  user?: User
  isLoginPage?: boolean;
  description?: string;
  title?: string;
  phoneNumber?: string;
  afterSendCode: (expiredTime: string, phoneNumber: string) => void;
}

const WhatsappPhoneNumbeForm: React.FC<WhatsappPhoneNumbeFormProps> = ({
  user,
  afterSendCode,
  isLoginPage,
  phoneNumber,
  description,
  title,
  inlineRender
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [currentUser] = useCurrentUser();
  const sendVerificationCodeToWhatsapp = useEnhanceApiClient(ApiClient.sendVerificationCodeToWhatsapp);

  return (
    <Formik
      validationSchema={PhoneNumberValidationSchema}
      initialValues={{ phoneNumber: phoneNumber || user?.phoneNumber || '' }}
      onSubmit={(values, { setSubmitting }) => {
        setIsLoading(true)
        sendVerificationCodeToWhatsapp({ phoneNumber: values.phoneNumber })
          .then((result) => {
            const [json] = result
            if ('error' in json) {
              setError('Looks like your number isn’t linked to WhatsApp. Please use a valid WhatsApp number');
            } else {
              setError('')
              afterSendCode(json.expiredAt, values.phoneNumber)
            }
          })
          .catch((e) => {
            setError('An internal error occurred');
            throw e;
          })
          .finally(() => {
            setIsLoading(false)
            setSubmitting(false)
          });
      }}
    >
      {(formik) => {
        return (
          <Form>
            <FormikObserver
              values={formik.values}
              onChange={(values) => {
                if (currentUser && currentUser.phoneNumberVerified) {
                  formik.setSubmitting(phoneNumber === values.phoneNumber)
                }
              }}
            />

            {!inlineRender && user ? <WelcomeBack user={user} isLoginPage={true} /> : (
              <div>
                <h2 className={styles.title}>{title}</h2>
                <div className="tw-my-6">{description}</div>
                <div className="form-group">
                  <div>
                    <InputPhoneNumber
                      name="phoneNumber"
                      className="form-control tw-rounded-lg tw-border tw-border-grey-lighter tw-border-solid"
                      aria-label="Input contact number"
                    />
                  </div>
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="invalid-feedback tw-block"
                  />
                </div>
              </div>
            )}

            <div className={classNames("invalid-feedback tw-block", { "tw-hidden": !error })}>{error}</div>

            <div className="tw-mb-6 tw-mt-3 tw-text-base">
              {isLoginPage ? 'Continue to login with phone number. ' : null}
              A verification code will be sent to your <span className="tw-text-brand-navy tw-font-semibold">WhatsApp</span>
            </div>

            <div className="form-group tw-text-center">
              <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary">
                <div className={classNames("spinner-border tw-w-4 tw-h-4 tw-mr-2", { "tw-hidden": !isLoading })}></div>
                {isLoading ? 'Sending code' : 'Continue'}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

const WhatasppPhoneNumberVerificationForm: React.FC<WhatasppPhoneNumberVerificationFormProps> = ({
  onResendCode,
  onVerifyPhoneNumber,
  phoneNumber,
  expiredTime,
  submitTitle,
  setFormType
}) => {
  const [error, setError] = useState<string>();
  const [isResend, setIsResend] = useState<boolean>(false);
  const [isTimeExpired, setIsTimeExpired] = useState<boolean>(false);
  const sendVerificationCodeToWhatsapp = useEnhanceApiClient(ApiClient.sendVerificationCodeToWhatsapp);

  const resendCodeToWhatsapp = (phoneNumber: string) => {
    setIsResend(true)
    sendVerificationCodeToWhatsapp({ phoneNumber: phoneNumber })
      .then((result) => {
        const [json] = result
        if ('error' in json) {
          setError(json.error);
        } else {
          onResendCode(json.expiredAt)
        }
      })
      .catch((e) => {
        setError('An internal error occurred');
        throw e;
      }).finally(() => setIsTimeExpired(false))
  }

  return (
    <Formik
      validationSchema={CodeValidationSchema}
      initialValues={{ code: '' }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        onVerifyPhoneNumber(values.code, phoneNumber, setError)
      }}
    >
      {(formik) => {
        return (
          <Form>
            <FormikObserver
              values={formik.values}
              onChange={(values) => { setError('') }}
            />
            <div
              className="tw-mb-6 tw-cursor-pointer"
              onClick={() => {
                setError('')
                setFormType('phone_number')
              }}
            >
              <ChevronLeft width={24} height={24} />
            </div>

            <h2 className={styles.title}>Enter Code</h2>
            <div>
              <div className="tw-my-3 tw-text-base mb-4">
                A verification code will be sent to your <span className="tw-text-brand-navy tw-font-semibold">WhatsApp</span>{' '}{phoneNumber}
              </div>
              <div className="form-group">
                  <Field
                    name="code"
                    placeholder="Enter Code"
                    className="form-control"
                    autocomplete="code"
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="invalid-feedback tw-block"
                  />
                  <div className={classNames("invalid-feedback tw-block", { "tw-hidden": !error })}>{error}</div>
              </div>

              {isTimeExpired ? (
                <div
                  onClick={() => { resendCodeToWhatsapp(phoneNumber) }}
                  className="tw-my-3 tw-text-base tw-text-grey-light mt-4 tw-underline tw-cursor-pointer">
                  {isResend ? 'Sending code' : 'Resend to WhatsApp'}
                </div>
              ) : (
                <div className="tw-my-3 tw-text-base tw-text-grey-light mt-4">
                  Resend to WhatsApp {' '}
                  <Countdown
                    as="span"
                    expiredTime={expiredTime}
                    onExpiredTime={() => {
                      setIsTimeExpired(true)
                      setIsResend(false)
                    }}
                  />
                </div>
              )}

              <div className="mb-2">
                <button type="submit" disabled={isTimeExpired} className="tw-rounded btn btn-block btn-primary">
                  {submitTitle}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

const WhatsappVerificationForm: ReactFCWithChildren<Props> = ({
  user,
  title,
  submitTitle,
  description,
  phoneNumber,
  isLoginPage,
  onVerifyPhoneNumber,
  children,
  inlineRender
}) => {
  const [formType, setFormType] = useState<'phone_number' | 'verification'>('verification')
  const [phone, setPhone] = useState<string>(phoneNumber || '');
  const defaultExpiredTime = String(dayjs().add(5, 'minute'))
  const [expiredTime, setExpiredTime] = useState<string>(defaultExpiredTime);

  return (
    <>
      {formType === 'phone_number'
        ? (
          <>
            <WhatsappPhoneNumbeForm
              inlineRender={inlineRender}
              title={title}
              user={user}
              isLoginPage={isLoginPage}
              phoneNumber={phone}
              description={description}
              afterSendCode={(expiredTime, phone) => {
                setFormType('verification')
                setExpiredTime(expiredTime)
                setPhone(phone)
              }}
            />
            {children}
          </>
        )
        : <WhatasppPhoneNumberVerificationForm
          phoneNumber={phone}
          submitTitle={submitTitle}
          expiredTime={expiredTime}
          setFormType={setFormType}
          onResendCode={(expiredTime) => (setExpiredTime(expiredTime))}
          onVerifyPhoneNumber={(code, phoneNumber, setError) => {
            onVerifyPhoneNumber && onVerifyPhoneNumber(code, phoneNumber, setError)
          }}
        />
      }
    </>
  )
};

export default WhatsappVerificationForm;
