import { useRef, useState } from 'react';
import MailSvg from 'public/images/svg_icons/mail.svg'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AppRoutes } from 'src/lib/routes';
import SocialLogin from 'src/components/SocialLogin';
import useRedirectIfLoggedIn from 'src/hooks/useRedirectIfLoggedIn';
import WhatsappVerificationForm from 'src/components/WhatsappVerificationForm';
import InfoSvg from 'public/images/svg_icons/info.svg';
import { RECAPTCHA_KEY } from 'src/lib/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { omit } from 'lodash-es';
import ChevronLeft from 'public/images/svg_icons/chevron_left.svg';
import useCsrfTokenUpdate from 'src/hooks/useCsrfTokenUpdate';
import ApiClient from 'src/lib/api_client';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useFlash from 'src/hooks/useFlash';
import EmailLogin from 'src/components/login/EmailLogin';
import { saveLastLoginMethod } from 'src/lib/last_login_method'
import classNames from 'classnames';
import { User } from 'src/types';
import FormikObserver from 'src/components/FormikObserver';
import { getLastLoginMethod } from 'src/lib/last_login_method'
import { MdEmail } from 'react-icons/md';
import { getAfterLoginUrlObject, notifyLoggedInEvent } from 'src/helpers';
import useCart from 'src/hooks/useCart';
import styles from 'src/styles/modules/components/login.module.scss';

type SignupFormProps = {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
  receiveNewletters: boolean;
  [RECAPTCHA_KEY]: string;
};

type FinishSigningFormProps = {
  inlineRender?: boolean;
  phoneNumber: string;
  setFormType: React.Dispatch<React.SetStateAction<'whatsapp_verification' | 'finishing_signing'>>
};

const SignUpValidationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Please enter email').typeError('Please enter email'),
  name: yup.string().required('Please enter your name').typeError('Please enter your name'),
  [RECAPTCHA_KEY]: yup
    .string()
    .required('Please complete captcha challenge')
    .typeError('Please complete captcha challenge'),
});

const FinishSigningForm: React.FC<FinishSigningFormProps> = ({
  inlineRender,
  phoneNumber,
  setFormType
}) => {
  const [existingUser, setExistingUser] = useState<User | undefined>()
  const signupAccount = useEnhanceApiClient(ApiClient.signupAccount);
  const updateCsrfToken = useCsrfTokenUpdate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const loginRef = useRedirectIfLoggedIn();
  const getLastLoginMethod = useEnhanceApiClient(ApiClient.getLastLoginMethod);
  const [error, setError] = useState<string>();
  const [, setCurrentUser, saveToken] = useCurrentUser();
  const [, setFlash] = useFlash();
  const router = useRouter();
  const [cart] = useCart()
  const { osc: orderShareCode } = router.query

  return (
    <>
      {existingUser ? (
        <div>
          <div className="tw-mb-6 tw-cursor-pointer" onClick={() => { setExistingUser(undefined) }}>
            <ChevronLeft width={24} height={24} />
          </div>
          <EmailLogin
            lastLoginMethod={existingUser}
            isEmailLoginPage={false}
            loginRef={loginRef}
          />
        </div>
      ) : (
        <Formik<SignupFormProps>
          validationSchema={SignUpValidationSchema}
          initialValues={{
            email: '',
            name: '',
            password: '',
            passwordConfirmation: '',
            phoneNumber: phoneNumber,
            receiveNewletters: true,
            [RECAPTCHA_KEY]: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            getLastLoginMethod({ id: values.email })
              .then((result) => {
                const [json] = result
                if ('error' in json) {
                  setError(json.error);
                } else if (json.user) {
                  if (json.user.isEmailOwnedByDiscardedUser) {
                    setError("Email has already been taken")
                  } else {
                    setExistingUser(json.user)
                  }
                } else {
                  updateCsrfToken()
                    .then((token) => {
                      signupAccount(
                        {
                          user: omit(values, [RECAPTCHA_KEY]), [RECAPTCHA_KEY]: values[RECAPTCHA_KEY],
                          osc: orderShareCode ? String(orderShareCode) : '',
                          cartId: cart.id
                        },
                        { 'X-CSRF-Token': token }
                      )
                        .then((result) => {
                          const [json] = result
                          if ('error' in json) {
                            setError(json.error);
                          } else {
                            saveToken(json.token);
                            setCurrentUser(json.user);
                            notifyLoggedInEvent(json.user)
                            saveLastLoginMethod(json.user)
                            setFlash({ notice: 'Logged in successfully.' });
                            if (!inlineRender) {
                              router.push(getAfterLoginUrlObject(router.query))
                            }
                          }
                        })
                        .catch((e) => {
                          setError('An internal error occurred');
                          throw e;
                        })
                    })
                }
              })
              .catch((e) => {
                setError('An internal error occurred');
                throw e;
              })
              .finally(() => setSubmitting(false));
          }}>
          {(formik) => {
            return (
              <Form>
                <FormikObserver
                  values={formik.values}
                  onChange={(values) => { setError('') }}
                />
                <div
                  className="tw-mb-6 tw-cursor-pointer"
                  onClick={() => {
                    setExistingUser(undefined)
                    setFormType('whatsapp_verification')
                  }}>
                  <ChevronLeft width={24} height={24} />
                </div>

                <h2 className={styles.title}>Finishing Signing Up</h2>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <InfoSvg width={24} height={24} />
                      </span>
                    </div>
                    <Field
                      name="name"
                      placeholder="Name"
                      className="form-control"
                      autocomplete="name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback tw-block"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <MailSvg width={24} height={24} />
                      </span>
                    </div>
                    <Field
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      autocomplete="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback tw-block"
                    />
                  </div>
                </div>

                <div className={classNames("invalid-feedback tw-block tw-mb-2", { "tw-hidden": !error })}>{error}</div>

                <div className="form-group">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
                    onChange={() => {
                      if (recaptchaRef.current) {
                        const recaptchaValue = recaptchaRef.current.getValue();
                        formik.setFieldValue(RECAPTCHA_KEY, recaptchaValue);
                      }
                    }}
                  />
                  <ErrorMessage
                    name={RECAPTCHA_KEY}
                    component="div"
                    className="invalid-feedback tw-block"
                  />
                </div>

                <div className="tw-text-center">
                  <button type="submit" className="btn btn-primary">
                    Continue
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

const WhatsappLogin: React.FC<{
  loginRef?: React.MutableRefObject<boolean>;
  inlineRender?: boolean;
  setLoginType?: React.Dispatch<React.SetStateAction<'whatsapp' | 'email' | undefined>>;
  title?: string;
}> = ({ loginRef, inlineRender, setLoginType, title = 'Log In or Sign Up' }) => {
  const router = useRouter();
  const loginMethod = getLastLoginMethod();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [formType, setFormType] = useState<'whatsapp_verification' | 'finishing_signing'>('whatsapp_verification')
  const [lastLoginMethod, setLastLoginMethod] = useState<User | undefined>(loginMethod)
  const loginWithWhatsapp = useEnhanceApiClient(ApiClient.loginWithWhatsapp);
  const [, setCurrentUser, saveToken] = useCurrentUser();
  const [, setFlash] = useFlash();
  const { code: invitationCode, osc: orderShareCode } = router.query
  const [cart] = useCart()

  return (
    <>
      {formType === 'whatsapp_verification' ? (
        <WhatsappVerificationForm
          inlineRender={inlineRender}
          user={lastLoginMethod}
          title={title}
          submitTitle="Login"
          isLoginPage={true}
          onVerifyPhoneNumber={(whatsappCode, phoneNumber, setErr) => {
            loginWithWhatsapp({
              whatsappCode: whatsappCode,
              phoneNumber: phoneNumber,
              code: invitationCode ? String(invitationCode) : '',
              osc: orderShareCode ? String(orderShareCode ) : '',
              cartId: cart.id
            })
              .then((result) => {
                const [json] = result
                if ('error' in json) {
                  setErr(json.error);
                } else if (json.user) {
                  saveToken(json.token);
                  setCurrentUser(json.user);
                  notifyLoggedInEvent(json.user)
                  saveLastLoginMethod(json.user)
                  if (!inlineRender) {
                    router.push(getAfterLoginUrlObject(router.query))
                  }
                } else {
                  setPhoneNumber(phoneNumber)
                  setFormType('finishing_signing')
                }
              })
              .catch((e) => {
                setErr('An internal error occurred');
                throw e;
              })
          }}

        >
          {!inlineRender && lastLoginMethod?.lastLoginMethod === 'whatsapp' ? (
            <div>
              <div className="tw-mt-2 tw-text-brand-navy">
                Not you? {' '}
                <span onClick={() => {
                  saveLastLoginMethod(undefined)
                  setLastLoginMethod(undefined)
                }} className="tw-text-orange tw-underline tw-cursor-pointer tw-lowercase tw-font-styleSans tw-font-semibold">
                  Use another account
                </span>
              </div>
            </div>
          ) : (
            <div >
              <div className="tw-flex tw-items-center tw-pt-6 tw-pb-8">
                <div className="tw-border-t tw-w-full tw-border-grey-lighter tw-border-solid" />
                <div className="tw-px-3 tw-text-brand-navy">Or</div>
                <div className="tw-border-t tw-w-full tw-border-grey-lighter tw-border-solid" />
              </div>

              <div className="tw-mb-2">
                <SocialLogin inlineRender={inlineRender} loginRef={loginRef}>
                  <button
                    onClick={() => {
                      setLastLoginMethod(undefined)
                      if (inlineRender && setLoginType) {
                        setLoginType(undefined)
                      } else {
                        router.push(AppRoutes.loginRoute({}, router.query).toUrl())
                      }
                    }}
                    type="button"
                    className="btn btn-block tw-text-brand-navy tw-bg-white tw-border tw-border-grey-lighter tw-border-solid tw-relative"
                  >
                    <MdEmail className="tw-absolute tw-left-3 tw-top-1 md:tw-top-2 tw-text-brand-navy" size={24} />
                    Continue with email
                  </button>
                </SocialLogin>
              </div>
            </div>
          )}
        </WhatsappVerificationForm>
      ) : (
        <FinishSigningForm inlineRender={inlineRender} phoneNumber={phoneNumber} setFormType={setFormType} />
      )}
    </>
  )
};

export default WhatsappLogin;
