import React, { useRef, useEffect, useState } from 'react'
import { useField } from 'formik'
import LockSvg from 'public/images/svg_icons/lock.svg';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = {
  name: string;
} & React.ComponentPropsWithoutRef<'input'>

const InputPassword: React.FC<Props> = ({ name, className = 'form-control', ...rest }) => {
  const [field, , helper] = useField(name)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className="input-group">
      <input
        type={showPassword ? "text" : "password"}
        className={`tw-border-r-0 ${className}`}
        {...field}
        onChange={(e) => helper.setValue(e.target.value)}
        {...rest}
      />
      <span
        onClick={() => { setShowPassword(!showPassword) }}
        className="input-group-text tw-cursor-pointer tw-rounded-none tw-border-l-0 tw-rounded-tr-lg tw-rounded-br-lg"
      >
        {showPassword ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
      </span>
    </div>
  )
}

export default InputPassword

